import Menu from '@geist-ui/react-icons/menu'

import { Divider, Drawer, Text } from '@geist-ui/react'
import { useState } from 'react'
import { MobileDrawerContent } from './MobileDrawerContent'

export const MobileHeader = () => {
  const [state, setState] = useState(false)

  return (
    <>
      <Drawer
        visible={state}
        onContentClick={() => setState(false)}
        onClose={() => setState(false)}
        placement="left"
      >
        <Drawer.Content>
          <MobileDrawerContent />
        </Drawer.Content>
      </Drawer>

      <div style={{
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <div>
          <Menu
            onClick={() => setState(true)}
          />
        </div>
        <div style={{
          textAlign: 'center',
          width: '100%'
        }}>
          <Text
            onClick={() => setState(true)}
            className="font-cursive"
            font="32px"
          >
            Martina & Alessandro
          </Text>
        </div>
      </div>
      <Divider style={{
        marginLeft: '30px',
        marginRight: '30px',
        marginTop: '-20px',
        marginBottom: '30px',
      }} />
    </>
  )
}
