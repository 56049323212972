import { Grid, Link, Spacer, Text } from '@geist-ui/react'
import { useTranslation } from 'react-i18next'

export const Sleeping = (props: { mobileView: boolean }) => {
  const { t } = useTranslation()

  return (
    <Grid.Container
      style={{ alignItems: 'center', flexDirection: 'column' }}
    >
      <Grid xs={18} lg={8}>
        <Text
          className="font-standard newline"
          font="20px"
          style={{ textAlign: 'center' }}
        >
          {t('sleeping_content')}
        </Text>
      </Grid>
      <Grid xs={18} lg={8}>
        <Text
          className="font-standard newline"
          font="20px"
          style={{ textAlign: 'center' }}
        >
          {t('sleeping_metro')}
        </Text>
      </Grid>
      <Spacer h={1} />
      <Grid xs={18} lg={8}>
        <Text
          className="font-standard newline"
          font="20px"
          style={{ textAlign: 'center' }}
        >
          {t('sleeping_neighborhood')}
        </Text>
      </Grid>
      <Grid>
        <Grid.Container
          style={{ paddingLeft: '40px', flexDirection: 'column' }}>
            <Grid>
            <Link color underline href="https://www.airbnb.it/rooms/856380529097684754?check_in=2024-07-05&check_out=2024-07-07&guests=1&adults=2&children=2&s=67&unique_share_id=6258265b-267a-454a-817c-fd88ed410aa9">
              <Text style={{ margin: 0 }} className="font-standard" font="18px">
                • Appia Antica Rooms
              </Text>
            </Link>
          </Grid>
          <Grid>
            <Link color underline href="https://www.sanpaologuesthouse.it">
              <Text style={{ margin: 0 }} className="font-standard" font="18px">
                • San Paolo Guest House
              </Text>
            </Link>
          </Grid>
          <Grid>
            <Grid.Container gap={0} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Grid>
                <Link color underline href="https://balconesulparco.wixsite.com/roma">
                  <Text style={{ margin: 0 }} className="font-standard" font="18px">
                    • Balcone sul parco
                  </Text>
                </Link>
              </Grid>
              <Grid>
                <Text style={{
                  marginLeft: props.mobileView ? '0px' : '5px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }} font="12px">{t('sleeping_hint_balcone')}</Text>
              </Grid>
            </Grid.Container>
          </Grid>
          <Grid>
            <Grid.Container gap={0} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Grid>
                <Link color underline href="https://casagiustiniano.wixsite.com/lang-ita">
                  <Text style={{ margin: 0 }} className="font-standard" font="18px">
                    • Casa Giustiniano
                  </Text>
                </Link>
              </Grid>
              <Grid>
                <Text style={{
                  marginLeft: props.mobileView ? '0px' : '5px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }} font="12px">{t('sleeping_hint_giustiniano')}</Text>
              </Grid>
            </Grid.Container>
          </Grid>
          <Grid>
            <Grid.Container gap={0} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Grid>
                <Link color underline href="http://www.palazzinamariani.it/">
                  <Text style={{ margin: 0 }} className="font-standard" font="18px">
                    • Palazzina Mariani
                  </Text>
                </Link>
              </Grid>
              <Grid>
                <Text style={{
                  marginLeft: props.mobileView ? '0px' : '5px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }} font="12px">{t('sleeping_hint_mariani')}</Text>
              </Grid>
            </Grid.Container>
          </Grid>
          <Grid>
            <Link color underline href="https://hello-roma-b-b.amenitiz.io/it/booking/room">
              <Text style={{ margin: 0 }} className="font-standard" font="18px">
                • Hello Roma BnB
              </Text>
            </Link>
          </Grid>
          <Grid>
            <Link color underline href="https://www.airbnb.com/rooms/1064252511523064792?check_in=2024-07-05&check_out=2024-07-07&guests=1&adults=2&s=67&unique_share_id=483a39a4-bc91-4e21-968e-2cbc3bac257f">
              <Text style={{ margin: 0 }} className="font-standard" font="18px">
                • Indoor 23
              </Text>
            </Link>
          </Grid>
          <Grid>
            <Link color underline href="https://www.airbnb.com/rooms/7975476?check_in=2024-07-05&check_out=2024-07-07&guests=4&adults=4&s=67&unique_share_id=e23acde5-6765-481f-81db-82f7d5f27785">
              <Text style={{ margin: 0 }} className="font-standard" font="18px">
                • Da Vinci 176
              </Text>
            </Link>
          </Grid>
          <Grid>
            <Link color underline href="https://www.airbnb.com/rooms/587570210412292125?check_in=2024-07-05&check_out=2024-07-07&guests=1&adults=2&s=67&unique_share_id=3d79af9c-3041-4091-957d-b935c53a95f4">
              <Text style={{ margin: 0 }} className="font-standard" font="18px">
                • Historic Garbatella Lotto
              </Text>
            </Link>
          </Grid>
        </Grid.Container>
      </Grid>
    </Grid.Container>
  )
}
