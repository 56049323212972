import RC, { CountdownRendererFn } from 'react-countdown'
import { Text } from '@geist-ui/react'
import { useTranslation } from 'react-i18next'

export const Countdown = () => {
  const { t } = useTranslation()

  const date = new Date('July 6, 2024 17:30')

  const renderer: CountdownRendererFn = ({
    days,
    completed,
  }) => {
    if (completed) {
      return <></>
    } else {
      return (
        <Text className="font-standard no-vertical-margin" font="18px">
          {t('countdown', { days })}
        </Text>
      )
    }
  }

  return (
    <>
      <RC date={date} renderer={renderer} />
    </>
  )
}
