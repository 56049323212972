import { Grid, Spacer, Text, Image } from '@geist-ui/react'
import { useTranslation } from 'react-i18next'

import peru from '../../resources/peru.jpeg'
import machu from '../../resources/machu.jpeg'
import plane from '../../resources/plane.png'

export const Gifts = (props: { mobileView: boolean }) => {
  const { t } = useTranslation()

  return (
    <Grid.Container
      justify="center"
      style={{ alignItems: 'center', flexDirection: 'column' }}
    >
      <Grid xs={18} lg={8}>
        <Text
          className="font-standard newline"
          font="20px"
          style={{ textAlign: 'center' }}
        >
          {t('gifts_content')}
        </Text>
      </Grid>
      <Grid>
        <Spacer h={1} />
        <Image src={plane} />
      </Grid>
      <Grid>
        <Grid.Container
          justify="center"
          style={{ alignItems: 'center', flexDirection: 'column' }}
        >
          <Spacer h={1} />
          <Grid xs={24}>
            <Text
              className="font-standard newline"
              style={{ marginBottom: 0, marginTop: 20, textAlign: 'center' }}
              font="20px"
            >
              {t('gifts_iban', { iban: 'IT71H0301503200000003894531' })}
            </Text>
          </Grid>
          <Spacer h={1} />
          <Grid xs={24}>
            <Text
              className="font-standard newline"
              style={{ textAlign: 'center' }}
              font="20px"
            >
              {t('gifts_address')}
            </Text>
          </Grid>
          <Spacer h={3} />
        </Grid.Container>
      </Grid>
      <Grid xs={24} lg={10}>
        <Image
          className={
            props.mobileView
              ? 'shadow-blue-top-left-mobile'
              : 'shadow-blue-top-left-desktop'
          }
          src={peru}
        ></Image>
      </Grid>
      <Grid>
        <Spacer h={3} />
      </Grid>
      <Grid xs={24} lg={10}>
        <Image
          className={
            props.mobileView
              ? 'shadow-red-bottom-right-mobile'
              : 'shadow-red-bottom-right-desktop'
          }
          src={machu}
        ></Image>
      </Grid>
    </Grid.Container>
  )
}
