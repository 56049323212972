import { Grid, Image, Link, Spacer, Text } from '@geist-ui/react'
import { useTranslation } from 'react-i18next'

import home from '../../resources/home.jpeg'
import sala from '../../resources/sala.webp'
import vigna from '../../resources/vigna.jpeg'
import vigna2 from '../../resources/vigna2.jpeg'

import Confetti from 'react-confetti'

import { useWindowSize, useTimeout } from 'react-use'
import { Countdown } from '../countdown/Countdown'

export const Home = (props: { mobileView: boolean }) => {
  const { t } = useTranslation()
  const { width, height } = useWindowSize()
  const [isComplete] = useTimeout(3000)

  return (
    <>
      <Confetti
        width={width}
        height={height}
        recycle={!isComplete()}
      />

      <Grid.Container
        style={{ alignItems: 'center', flexDirection: 'column' }}
        gap={0}
        justify="center"
        width="100%"
      >
        {props.mobileView ? <></> : (<Grid>
          <Text
            style={{ marginTop: '10px', marginBottom: '10px' }}
            className="font-cursive"
            font="45px"
          >
            Martina & Alessandro
          </Text>
        </Grid>)}
        <Grid>
          <Text
            style={{ marginTop: '0px', marginBottom: '10px' }}
            className="font-cursive"
            font="35px"
          >
            {t('sixth_july')}
          </Text>
        </Grid>
        <Grid style={{ marginBottom: '20px' }}>
          <Countdown />
        </Grid>
        <Grid>
          <Image className='home-image' src={home} />
        </Grid>
        <Grid xs={24} lg={6} xl={6} style={{ marginTop: '20px' }}>
          <Text
            style={{
              textAlign: 'center',
              marginBottom: 5,
            }}
            className="font-standard"
            font="20px"
          >
            {t('information_content_first')}
          </Text>
        </Grid>
        <Grid xs={24}>
          <Link href="https://www.google.com/maps/place/Campidoglio/@41.8933632,12.4802269,17z/data=!3m1!4b1!4m6!3m5!1s0x132f604c9b68c939:0xe1efbb238bd1c51!8m2!3d41.8933592!4d12.4828018!16zL20vMDEyY18y?authuser=0&entry=tts">
            <Text style={{ margin: 0 }} className="font-cursive" font="25px">
              Sala Rossa in Campidoglio
            </Text>
          </Link>
        </Grid>
        <Grid xs={24}>
          <Link color underline href="https://www.google.com/maps/place/Campidoglio/@41.8933632,12.4802269,17z/data=!3m1!4b1!4m6!3m5!1s0x132f604c9b68c939:0xe1efbb238bd1c51!8m2!3d41.8933592!4d12.4828018!16zL20vMDEyY18y?authuser=0&entry=tts">
            <Text style={{ margin: 0 }} className="font-standard" font="15px">
              Piazza del Campidoglio - 00186 Roma
            </Text>
          </Link>
        </Grid>
        <Spacer h={3} />
        <Grid xs={24} lg={12}>
          <Image
            className={
              props.mobileView
                ? 'shadow-blue-top-left-mobile'
                : 'shadow-blue-top-left-desktop'
            }
            src={sala}
          ></Image>
        </Grid>
        <Spacer h={2} />
        <Grid xs={24}>
          <Text
            style={{ textAlign: 'center', marginBottom: 5, marginTop: 0 }}
            className="font-standard"
            font="20px"
          >
            {t('information_content_second')}
          </Text>
        </Grid>
        <Grid xs={24}>
          <Link href="https://www.google.com/maps/place/Vigna+Mereghiana/@41.8601596,12.5118557,17z/data=!3m1!4b1!4m6!3m5!1s0x13258b4453ac0713:0x575eeb592e9ca8b1!8m2!3d41.8601596!4d12.5118557!16s%2Fg%2F11rj93jmv8?authuser=0&entry=ttu">
            <Text style={{ margin: 0 }} className="font-cursive" font="25px">
              Vigna Mereghiana
            </Text>
          </Link>
        </Grid>
        <Grid xs={24}>
          <Link color underline href="https://www.google.com/maps/place/Vigna+Mereghiana/@41.8601596,12.5118557,17z/data=!3m1!4b1!4m6!3m5!1s0x13258b4453ac0713:0x575eeb592e9ca8b1!8m2!3d41.8601596!4d12.5118557!16s%2Fg%2F11rj93jmv8?authuser=0&entry=ttu">
            <Text style={{ margin: 0 }} className="font-standard" font="15px">
              Via Appia Antica, 109 - 00178 Roma
            </Text>
          </Link>
        </Grid>
        <Grid xs={24}>
          <Text
            style={{ textAlign: 'center', marginTop: 5 }}
            className="font-standard newline"
            font="20px"
          >
            {t('information_content_third')}
          </Text>
        </Grid>
        <Spacer h={1} />
        <Grid xs={24} lg={12}>
          <Image
            className={
              props.mobileView
                ? 'shadow-red-bottom-right-mobile'
                : 'shadow-red-bottom-right-desktop'
            }
            src={vigna}
          ></Image>
        </Grid>
        <Spacer h={props.mobileView ? 3 : 6} />
        <Grid xs={24} lg={12}>
          <Image
            className={
              props.mobileView
                ? 'shadow-blue-top-left-mobile'
                : 'shadow-blue-top-left-desktop'
            }
            src={vigna2}
          ></Image>
        </Grid>
        <Grid xs={24}>
          <Text className="font-cursive" font="35px" style={{ marginBottom: 10 }}>
            {t('information_schedule')}
          </Text>
        </Grid>
        <Grid xs={24}>
          <Text className="font-standard no-vertical-margin" font="18px">
            17:30 👩‍❤️‍👨
          </Text>
          <Text i className="font-standard" font="18px" style={{ marginTop: 0 }}>
            {t('information_schedule_ceremony')}
          </Text>
        </Grid>
        <Grid xs={24}>
          <Text className="font-standard no-vertical-margin" font="18px">
            19:00 🥂
          </Text>
          <Text i className="font-standard" font="18px" style={{ marginTop: 0 }}>
            {t('information_schedule_party')}
          </Text>
        </Grid>
        <Grid xs={24}>
          <Text className="font-standard no-vertical-margin" font="18px">
            20:30 😋
          </Text>
          <Text i className="font-standard" font="18px" style={{ marginTop: 0 }}>
            {t('information_schedule_dinner')}
          </Text>
        </Grid>
        <Grid xs={24}>
          <Text className="font-standard no-vertical-margin" font="18px">
            23:00 💃🏻
          </Text>
          <Text i className="font-standard" font="18px" style={{ marginTop: 0 }}>
            {t('information_schedule_dance')}
          </Text>
        </Grid>
      </Grid.Container>
    </>
  )

}
