import { Text } from '@geist-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const sleeping = 'sleeping'

export const Sleeping = () => {
  const { t } = useTranslation()

  return (
    <Link to="/sleeping" className="header-link">
      <Text font="25px">{t(sleeping).toUpperCase()}</Text>
    </Link>
  )
}
